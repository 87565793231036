<template>
  <div>
    <div id="su-permissions-page">
      <div class="flex-column">
        <h1 class="h1-title">PPSR Permissions</h1>
        <div class="margin-top-2 flex-row">
          <input
            class="flex-grow text-field text-center"
            placeholder="Dealership Name"
            type="text"
            v-model="searchText"
          />
        </div>

        <table class="results-table">
          <tr class="header">
            <th>Dealership</th>
            <th>Can Request PPSR</th>
          </tr>
          <tr
            v-for="dealership in dealerships.filter(dealership => {
              return dealership.name.toLowerCase().includes(searchText.toLowerCase());
            })"
            v-bind:key="dealership.url"
            class="data-row"
          >
            <td class="padding-half">{{ dealership.name }}</td>
            <td>
              <input type="checkbox" v-model="dealership.ppsr_status" @change="updateDealership(dealership, $event)" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchDealershipsPPSRStatus, updateDealershipPPSRStatus } from "../../api/Dealership.js";
import { mapActions } from "vuex";

export default {
  name: "SuperUserPermissions",
  data: function () {
    return {
      dealerships: [],
      searchText: ""
    };
  },
  mounted: function () {
    fetchDealershipsPPSRStatus()
      .then(response => {
        this.dealerships = response;
      })
      .catch(error => {
        this.addError(error);
      });
  },
  methods: {
    updateDealership: function (dealership, event) {
      updateDealershipPPSRStatus(dealership, event.target.checked).catch(error => {
        this.addError(error);
      });
    }
  },
  ...mapActions({
    addError: "errorStore/addError"
  })
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#su-permissions-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
